<template>
  <v-container class="general">
    <PageTitle :title="$t('printout_page.title')" />
    <v-layout class="row wrap">
      <v-flex class="xs12">
        <v-text-field
          v-model="printoutTemplate.name"
          v-validate="'required'"
          :error-messages="errors.collect('name')"
          data-vv-name="name"
          data-vv-as="name"
          :disabled="editMode"
          label="Name"
        ></v-text-field>
      </v-flex>
      <v-flex class="xs12 mb-4">
        <v-text-field
          v-model="printoutTemplate.title"
          v-validate="'required'"
          :error-messages="errors.collect('title')"
          data-vv-name="title"
          data-vv-as="title"
          label="Title"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 class="mb-3">
        <div class="font-weight-bold">Placeholders</div>
        <v-combobox
          v-model="placeholderInput"
          :items="filtredPlaceholders"
          :search-input.sync="search"
          @input="addPlaceholder"
          @keyup.enter="selectPlaceholder"
          placeholder="Start typing to Search"
          no-filter
          ref="placeholderInput"
        >
          <template slot="no-data">
            <div class="px-3 py-2 caption grey--text">Nothing found</div>
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.name }}
          </template>
        </v-combobox>
      </v-flex>
      <v-flex
        v-if="printoutPlaceholders && printoutPlaceholders.length"
        class="xs12 mb-5"
      >
        <v-layout
          v-for="(placeholder, i) in printoutPlaceholders"
          :key="`placeholder-${i}`"
          class="row no-wrap align-center mb-1"
        >
          <v-flex xs2 class="caption grey--text">{{ placeholder.name }}</v-flex>
          <v-flex xs2 class="px-1">
            <v-text-field
              v-model="placeholder.top"
              label="Distance from top"
              hide-details
            ></v-text-field
          ></v-flex>
          <v-flex xs2 class="px-1">
            <v-select
              v-model="placeholder.textAlign"
              :items="alignments"
              item-text="name"
              item-value="value"
              :label="'Alignment'"
              hide-details
            >
              <template slot="item" slot-scope="data">
                <span class="slot-item">{{ data.item.name }}</span>
              </template>
            </v-select>
          </v-flex>
          <v-flex v-if="placeholder.textAlign === 'left'" xs2 class="px-1">
            <v-text-field
              v-model="placeholder.left"
              label="Distance from left side"
              hide-details
            ></v-text-field
          ></v-flex>
          <v-flex v-if="placeholder.textAlign === 'right'" xs2 class="px-1">
            <v-text-field
              v-model="placeholder.right"
              label="Distance from right side"
              hide-details
            ></v-text-field
          ></v-flex>
          <v-flex xs2 class="px-1">
            <v-select
              v-model="placeholder.fontFamily"
              :items="fonts"
              item-text="name"
              item-value="value"
              :label="'Font'"
              hide-details
            >
              <template slot="item" slot-scope="data">
                <span class="slot-item">{{ data.item.name }}</span>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs1 class="px-1">
            <v-text-field
              v-model="placeholder.fontSize"
              label="Font size"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs2 class="px-1">
            <v-select
              v-model="placeholder.fontStyles"
              :items="fontStyles"
              item-text="name"
              item-value="value"
              :label="'Font styles'"
              hide-details
              multiple
            >
              <template slot="item" slot-scope="data">
                <span class="slot-item">{{ data.item.name }}</span>
              </template>
            </v-select>
          </v-flex>
          <v-btn icon class="theme-primary" @click="removePlaceholder(i)">
            <font-awesome-icon icon="trash" />
          </v-btn>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="font-weight-bold">Preview</v-flex>
      <v-flex
        v-if="!printoutTemplate.background_image"
        xs12
        class="mb-5 text-xs-center"
      >
        <MediaManager
          v-model="printoutBackground"
          :media-type="'images'"
          :label="''"
        />
      </v-flex>
      <v-flex v-if="printoutTemplate.background_image" xs12 class="mt-3 mb-5">
        <v-card class="preview mx-auto">
          <div
            v-for="(placeholder, i) in printoutPlaceholders"
            :key="`placeholder-${i}`"
            :style="placeholderStyles(placeholder)"
          >
            {{ placeholder.name }}
          </div>
          <v-img
            :src="printoutTemplate.background_image"
            class="preview"
          ></v-img>
          <v-btn
            fab
            dark
            small
            absolute
            top
            right
            color="red"
            class="delete-btn"
            @click="removeBackground"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card>
      </v-flex>
      <v-flex xs12 class="text-xs-center">
        <v-btn
          round
          class="text-none"
          @click="$router.push({ name: 'printout_templates' })"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          v-if="!printoutTemplateId"
          round
          class="theme-primary-bg theme-on-primary text-none"
          :loading="isLoading"
          @click="createPrintoutTemplate"
          >{{ $t("create") }}</v-btn
        >
        <v-btn
          v-if="printoutTemplateId"
          round
          class="theme-primary-bg theme-on-primary text-none"
          :loading="isLoading"
          @click="updatePrintoutTemplate"
          >{{ $t("save") }}</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    placeholderInput: null,
    search: null,
    printoutBackground: null,
    printoutTemplate: {
      background_image: null,
    },
    printoutPlaceholders: [],
    groupGeneralAttributes: [],
  }),
  computed: {
    printoutTemplateId() {
      return this.$route.params.printout_template_id;
    },
    editMode() {
      return this.printoutTemplateId ? true : false;
    },
    fonts() {
      return [
        { name: "DejaVu Sans", value: "DejaVu Sans" },
        { name: "DejaVu Serif", value: "DejaVu Serif" },
        { name: "Caveat", value: "Caveat" },
      ];
    },
    fontStyles() {
      return [
        { name: "Bold", value: "bold" },
        { name: "Italic", value: "italic" },
        { name: "Underline", value: "underline" },
      ];
    },
    alignments() {
      return [
        { name: "Left", value: "left" },
        { name: "Center", value: "center" },
        { name: "Right", value: "right" },
      ];
    },
    placeholders() {
      return [
        {
          id: "default-0",
          name: "Full name",
          placeholder:
            '[user-property name="first_name" user_id]First name[/user-property] [user-property name="last_name" user_id]Last name[/user-property]',
          top: 0,
          textAlign: "center",
          fontFamily: "DejaVu Sans",
          fontSize: 4,
        },
        {
          id: "default-1",
          name: "First name",
          placeholder:
            '[user-property name="first_name" user_id]First name[/user-property]',
          top: 130,
          textAlign: "center",
          fontFamily: "DejaVu Sans",
          fontSize: 4,
        },
        {
          id: "default-2",
          name: "Last name",
          placeholder:
            '[user-property name="last_name" user_id]Last name[/user-property]',
          top: 144,
          textAlign: "center",
          fontFamily: "DejaVu Sans",
          fontSize: 4,
        },
      ];
    },
    availablePlaceholders() {
      let selectedPlaceholders = [];

      if (this.printoutPlaceholders) {
        selectedPlaceholders = this.printoutPlaceholders.map((el) => el.id);
      }

      return this.placeholders.filter(
        (el) => !selectedPlaceholders.includes(el.id),
      );
    },
    filtredPlaceholders() {
      if (!this.search || (this.search && this.search.id)) {
        return this.availablePlaceholders;
      }
      return this.availablePlaceholders.filter((el) =>
        el.name.toUpperCase().includes(this.search.toUpperCase()),
      );
    },
  },
  mounted() {
    this.listGroupGeneralAttributes();
    this.getPrintoutTemplate();
  },
  methods: {
    addPlaceholder(placeholder) {
      if (!placeholder || !placeholder.name) return;

      this.printoutPlaceholders.push(placeholder);

      this.$nextTick(() => {
        this.search = null;
        this.placeholderInput = null;

        this.$refs.placeholderInput.blur();
      });
    },
    selectPlaceholder() {
      if (this.filtredPlaceholders && this.filtredPlaceholders.length === 1) {
        this.addPlaceholder(this.filtredPlaceholders[0]);
      }
    },
    removePlaceholder(i) {
      this.$delete(this.printoutPlaceholders, i);
    },
    removeBackground() {
      this.printoutBackground = null;
      this.printoutTemplate.background_image = null;
    },
    transformContentForSave(placeholders) {
      const content = [];

      for (const placeholder of placeholders) {
        const styleArray = [`position: absolute`, `z-index: 1`];
        const dataAttributesArray = [`data-placeholder="${placeholder.id}"`];

        // top
        const top = placeholder.top || 0;

        styleArray.push(`top: ${top > 0 ? top + "mm" : 0}`);

        dataAttributesArray.push(`data-top="${top}"`);

        // right
        const right = placeholder.right || 0;

        styleArray.push(`right: ${right > 0 ? right + "mm" : 0}`);

        dataAttributesArray.push(`data-right="${right}"`);

        // left
        const left = placeholder.left || 0;

        styleArray.push(`left: ${left > 0 ? left + "mm" : 0}`);

        dataAttributesArray.push(`data-left="${left}"`);

        // text-align
        if (placeholder.textAlign) {
          styleArray.push(`text-align: ${placeholder.textAlign}`);

          dataAttributesArray.push(
            `data-text-align="${placeholder.textAlign}"`,
          );
        }

        // font-family
        if (placeholder.fontFamily) {
          styleArray.push(`font-family: ${placeholder.fontFamily}`);

          dataAttributesArray.push(
            `data-font-family="${placeholder.fontFamily}"`,
          );
        }

        // font-size
        const fontSize = placeholder.fontSize || 4;

        styleArray.push(`font-size: ${fontSize > 0 ? fontSize : 4}mm`);

        dataAttributesArray.push(`data-font-size="${fontSize}"`);

        // font-style
        if (placeholder.fontStyles) {
          if (placeholder.fontStyles.includes("bold")) {
            styleArray.push(`font-weight: bold`);
          }

          if (placeholder.fontStyles.includes("italic")) {
            styleArray.push(`font-style: italic`);
          }

          if (placeholder.fontStyles.includes("underline")) {
            styleArray.push(`text-decoration: underline`);
          }

          dataAttributesArray.push(
            `data-font-styles="${placeholder.fontStyles}"`,
          );
        }

        const style = styleArray.join("; ").toString();
        const dataAttributes = dataAttributesArray.join(" ").toString();

        const element =
          `<style>@import url("https://fonts.googleapis.com/css2?family=Caveat&display=block");</style>` +
          `<div style="${style}" ${dataAttributes}>${placeholder.placeholder}</div>`;

        content.push(element);
      }

      return content.join("").toString();
    },
    transformPlaceholdersForEdit(content) {
      if (!content) return [];

      const div = document.createElement("div");

      div.innerHTML = content;

      const elements = div.getElementsByTagName("div");

      const result = [];

      for (const element of elements) {
        const placeholderId = element.getAttribute("data-placeholder");

        const placeholder = this.placeholders.find(
          (el) => el.id === placeholderId,
        );

        placeholder.top = element.getAttribute("data-top");
        placeholder.right = element.getAttribute("data-right");
        placeholder.left = element.getAttribute("data-left");
        placeholder.textAlign = element.getAttribute("data-text-align");
        placeholder.fontFamily = element.getAttribute("data-font-family");
        placeholder.fontSize = element.getAttribute("data-font-size");
        const fontStyles = element.getAttribute("data-font-styles");
        placeholder.fontStyles = fontStyles ? fontStyles.split(",") : null;

        result.push(placeholder);
      }

      return result;
    },
    async createPrintoutTemplate() {
      const valid = await this.$validator.validate();

      if (!valid) return;

      this.isLoading = true;

      const specs = {
        name: this.printoutTemplate.name,
        title: this.printoutTemplate.title,
        template_name: "base_template",
        background_image: this.printoutTemplate.background_image,
        content: this.transformContentForSave(this.printoutPlaceholders),
      };

      this.$api.printoutTemplates.create(specs).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: `Template created`,
          });

          this.$router
            .push({
              name: "printout_templates_edit",
              params: {
                printout_template_id: response.data,
              },
            })
            .catch(() => {});

          this.getPrintoutTemplate();
        },
        (error) => {
          this.isLoading = false;
          this.errorMessageShow(error);
        },
      );
    },
    getPrintoutTemplate() {
      if (!this.printoutTemplateId) return;

      this.isLoading = true;

      this.$api.printoutTemplates.get(this.printoutTemplateId).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.printoutTemplate = {
            id: response.data.id,
            name: response.data.name,
            title: response.data.title,
            background_image: response.data.artifact.background_image,
          };

          this.printoutPlaceholders = this.transformPlaceholdersForEdit(
            response.data.dynamic_content.content,
          );
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async updatePrintoutTemplate() {
      const valid = await this.$validator.validate();

      if (!valid) return;

      this.isLoading = true;

      const specs = [
        this.printoutTemplate.id,
        {
          name: this.printoutTemplate.name,
          title: this.printoutTemplate.title,
          template_name: "base_template",
          background_image: this.printoutTemplate.background_image,
          content: this.transformContentForSave(this.printoutPlaceholders),
        },
      ];

      this.$api.printoutTemplates.update(...specs).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: `Template updated`,
          });

          this.getPrintoutTemplate();
        },
        (error) => {
          this.isLoading = false;
          this.errorMessageShow(error);
        },
      );
    },
    listGroupGeneralAttributes() {
      this.$api.groupGeneralAttributes.list({ per_page: 500, page: 1 }).then(
        (response) => {
          this.groupGeneralAttributes = response.data.data;
        },
        () => {},
      );
    },
    placeholderStyles(placeholder) {
      if (!placeholder) return {};

      const styles = {
        position: "absolute",
        top: `${placeholder.top}mm`,
        textAlign: placeholder.textAlign,
        fontFamily: placeholder.fontFamily,
        zIndex: 1,
      };

      styles.right =
        placeholder.right && placeholder.right > 0
          ? `${placeholder.right}mm`
          : 0;

      styles.left =
        placeholder.left && placeholder.left > 0 ? `${placeholder.left}mm` : 0;

      styles.fontSize =
        placeholder.fontSize && placeholder.fontSize > 0
          ? `${placeholder.fontSize}mm`
          : "4mm";

      if (placeholder.fontStyles) {
        if (placeholder.fontStyles.includes("bold")) {
          styles.fontWeight = "bold";
        }

        if (placeholder.fontStyles.includes("italic")) {
          styles.fontStyle = "italic";
        }

        if (placeholder.fontStyles.includes("underline")) {
          styles.textDecoration = "underline";
        }
      }

      return styles;
    },
  },
  watch: {
    printoutBackground(val) {
      if (val && val.url) {
        this.printoutTemplate.background_image = val.url;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.preview {
  width: 210mm;
  height: 297mm;
}

.delete-btn {
  top: -20px;
  right: -20px;
  z-index: 1;
}
</style>
